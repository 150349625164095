import React, { useEffect, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import AutoLogout from './components/AutoLogout';
import { AuthProvider, useAuth } from './context/AuthContext';
import { LoadingProvider, useLoading } from './context/LoadingContext';
import { fetchCSRFToken } from './utils/csrf';
import Layout from './components/Layout';
import Loading from './components/Loading';
import './App.css';

// Lazy load components
const LoginPage = lazy(() => import('./components/LoginPage'));
const HomePage = lazy(() => import('./components/HomePage'));
const CheckEligibility = lazy(() => import('./components/CheckEligibility'));
const EligibilityResults = lazy(() => import('./components/EligibilityResults'));
const AddUtilization = lazy(() => import('./components/AddUtilization'));
const AddUtilizationForm = lazy(() => import('./components/AddUtilizationForm'));
const UtilizationResults = lazy(() => import('./components/UtilizationResults'));
const ProviderRoster = lazy(() => import('./pages/ProviderRoster'));
const UploadProviders = lazy(() => import('./pages/UploadProviders'));
const UtilizationRoster = lazy(() => import('./pages/UtilizationRoster'));
const UtilizationList = lazy(() => import('./pages/UtilizationList'));
const UserManagement = lazy(() => import('./pages/UserManagement'));
const PricingManagement = lazy(() => import('./pages/PricingManagement'));
const MemberRoster = lazy(() => import('./pages/MemberRoster'));
const AddRegion = lazy(() => import('./pages/AddRegion'));
const RegionsList = lazy(() => import('./pages/RegionsList'));
const AddType = lazy(() => import('./pages/AddType'));
const TypesList = lazy(() => import('./pages/TypesList'));
const AddProvider = lazy(() => import('./pages/AddProvider'));
const EditProvider = lazy(() => import('./pages/EditProvider'));
const ProviderListDB = lazy(() => import('./pages/ProviderListDB'));
const UserProfile = lazy(() => import('./pages/UserProfile'));
const DeleteUser = lazy(() => import('./pages/DeleteUser'));
const ProviderUsers = lazy(() => import('./pages/ProviderUsers'));
const ProductPriceList = lazy(() => import('./pages/ProductPriceList'));
const UploadPage = lazy(() => import('./pages/UploadPage'));
const PremiumCalculator = lazy(() => import('./pages/PremiumCalculator'));
const TransactionForm = lazy(() => import('./pages/TransactionForm'));
const CoremedProducts = lazy(() => import('./pages/CoremedProducts'));  // New component for Coremed Products
const UploadProducts = lazy(() => import('./pages/UploadProducts'));    // Upload Products component

function App() {
  // CSRF token initialization
  useEffect(() => {
    const initializeCSRF = async () => {
      try {
        await fetchCSRFToken();
      } catch (error) {
        console.error('CSRF token fetch failed:', error);
      }
    };
    initializeCSRF();
  }, []);

  return (
    <LoadingProvider>
      <AuthProvider>
        <Router>
          <AutoLogout />
          <LoadingOverlay />
          <div>
            <Navbar />
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route path="/" element={<LoginPage />} />
                <Route path="/home" element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
                <Route path="/check-eligibility" element={<ProtectedRoute><Layout><CheckEligibility /></Layout></ProtectedRoute>} />
                <Route path="/results" element={<ProtectedRoute><Layout><EligibilityResults /></Layout></ProtectedRoute>} />
                <Route path="/add-utilization" element={<ProtectedRoute><Layout><AddUtilization /></Layout></ProtectedRoute>} />
                <Route path="/add-utilization-form" element={<ProtectedRoute><Layout><AddUtilizationForm /></Layout></ProtectedRoute>} />
                <Route path="/utilization-results" element={<ProtectedRoute><Layout><UtilizationResults /></Layout></ProtectedRoute>} />
                <Route path="/provider-roster" element={<ProtectedRoute><Layout><ProviderRoster /></Layout></ProtectedRoute>} />
                <Route path="/add-provider" element={<ProtectedRoute><Layout><AddProvider /></Layout></ProtectedRoute>} />
                <Route path="/edit-provider/:id" element={<ProtectedRoute><Layout><EditProvider /></Layout></ProtectedRoute>} />
                <Route path="/provider-list-db" element={<ProtectedRoute><Layout><ProviderListDB /></Layout></ProtectedRoute>} />
                <Route path="/utilization-roster" element={<ProtectedRoute><Layout><UtilizationRoster /></Layout></ProtectedRoute>} />
                <Route path="/view-utilization" element={<ProtectedRoute><Layout><UtilizationList /></Layout></ProtectedRoute>} />
                <Route path="/user-management/*" element={<ProtectedRoute><Layout><UserManagement /></Layout></ProtectedRoute>} />
                <Route path="/pricing-roster" element={<ProtectedRoute><Layout><PricingManagement /></Layout></ProtectedRoute>} />
                <Route path="/member-roster" element={<ProtectedRoute><Layout><MemberRoster /></Layout></ProtectedRoute>} />
                <Route path="/add-region" element={<ProtectedRoute><Layout><AddRegion /></Layout></ProtectedRoute>} />
                <Route path="/regions" element={<ProtectedRoute><Layout><RegionsList /></Layout></ProtectedRoute>} />
                <Route path="/add-type" element={<ProtectedRoute><Layout><AddType /></Layout></ProtectedRoute>} />
                <Route path="/types" element={<ProtectedRoute><Layout><TypesList /></Layout></ProtectedRoute>} />
                <Route path="/user-profile" element={<ProtectedRoute><Layout><UserProfile /></Layout></ProtectedRoute>} />
                <Route path="/delete_user/:userId" element={<ProtectedRoute><Layout><DeleteUser /></Layout></ProtectedRoute>} />
                <Route path="/provider-users" element={<ProtectedRoute><Layout><ProviderUsers /></Layout></ProtectedRoute>} />
                <Route path="/product-prices" element={<ProtectedRoute><Layout><ProductPriceList /></Layout></ProtectedRoute>} />
                <Route path="/upload-product-prices" element={<ProtectedRoute><Layout><UploadPage /></Layout></ProtectedRoute>} />
                <Route path="/premium-calculator" element={<ProtectedRoute><Layout><PremiumCalculator /></Layout></ProtectedRoute>} />
                <Route path="/upload-provider-roster" element={<ProtectedRoute><Layout><UploadProviders /></Layout></ProtectedRoute>} />
                <Route path="/transaction-form" element={<ProtectedRoute><Layout><TransactionForm /></Layout></ProtectedRoute>} />
                <Route path="/coremed-products" element={<ProtectedRoute><Layout><CoremedProducts /></Layout></ProtectedRoute>} />  {/* Added route for Coremed Products */}
                <Route path="/upload-products" element={<ProtectedRoute><Layout><UploadProducts /></Layout></ProtectedRoute>} />  {/* Added route for Upload Products */}
              </Routes>
            </Suspense>
          </div>
        </Router>
      </AuthProvider>
    </LoadingProvider>
  );
}

// ProtectedRoute component to handle authentication checks
function ProtectedRoute({ children }) {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <LoginPage />;  // Redirect to login page if not authenticated
  }

  return children;
}

// Loading overlay to show loading spinner when necessary
function LoadingOverlay() {
  const { isLoading } = useLoading();
  return isLoading ? <Loading /> : null;
}

export default App;
